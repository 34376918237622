import * as React from "react";
import {RouteProps} from "./index";
import PlayAndWin from "../../static/image/svg/play-and-win.svg";
import {StaticImage} from "gatsby-plugin-image";
import TvButton from "../../static/image/svg/tv-button.svg";
import Play from "../../static/image/svg/play.svg";
import {Link} from "gatsby";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import {GameContext, GameContextPage} from "../game/GameContext";
import {useContext} from "react";

const InfoPage: React.FC = () => {
    const { setPageId } = useContext(GameContext);

    return (
        <div className="info">
            <Zoom bottom duration={1000}>
                <a onClick={() => setPageId(GameContextPage.Instructions)}>
                    <PlayAndWin />
                </a>
            </Zoom>
            <div className="text">
                <h1>
                    Expert memory
                </h1>
                <p>
                    Scoor in 30 seconden zoveel mogelijk punten
                </p>
            </div>

            <div className="tv">
                <StaticImage
                    src="../../static/image/tv.png"
                    placeholder="tracedSVG"
                    alt='Samsung UHD 55" TV'
                />
                <TvButton />
            </div>

            <Fade bottom duration={500} delay={500}>
                <a onClick={() => setPageId(GameContextPage.Instructions)}>
                    <Play />
                </a>
            </Fade>
        </div>
    )
}

export default InfoPage;
