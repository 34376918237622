import * as React from "react";
import {useContext} from "react";
import PlayAndWin from "../../static/image/svg/play-and-win.svg";
import {GameContext, GameContextPage} from "../game/GameContext";

const ConditionsPage: React.FC = () => {
    const { setPageId } = useContext(GameContext);

    const conditions = [
        'De actieperiode van deze Expert Play & Win memoy is van woensdag 25 mei tot 11 juli 2021.',
        'De deelnemer die de hoogste score heeft na deze periode wint een Samsung UHD 55” TV t.w.v. €599.-',
        'Bij gelijke hoogste scores van de eerste 4 deelnemers wordt er geloot en ontvangen de nummers 2, 3 en 4 een cadeaubon t.w.v. €25.-',
        'Voor deelname moet je tenminste 18 jaar of ouder zijn of toestemming hebben van je ouder(s).',
        'Voor het spelen van dit memory spel en het kans maken op de prijs zijn geen kosten verbonden.',
        'Medewerkers van Expert Frits van Enk en van betrokken partijen zijn uitgesloten van deelname.',
        'Deelnemers mogen zo vaak meespelen als ze willen.',
        'Expert Frits van Enk neemt zelf contact op met de prijswinnaar.'
    ];

    return (
        <div className="conditions">
            <PlayAndWin className="logo" />

            <h1>
                Actie voorwaarden
            </h1>

            <ol>
                {conditions.map((condition, key) => (
                    <li key={key}>
                        <p>
                            {condition}
                        </p>
                    </li>
                ))}
            </ol>

            <a onClick={() => setPageId(GameContextPage.Submit)}>
                Terug naar de game
            </a>
        </div>
    )
}

export default ConditionsPage;
