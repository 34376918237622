import {GameContext, GameContextPage} from "./GameContext";
import React, {FC, useState} from "react";

export const GameContextProvider: FC = ({ children }) => {
    const [pageId, setPageId] = useState(GameContextPage.Landing);

    return (
        <GameContext.Provider value={{ pageId, setPageId }}>
            {children}
        </GameContext.Provider>
    )
};
