import React, { FC } from 'react'
import classNames from "classnames";
import {CardTypes} from "../util/cards";
import DefaultCard from "../../static/image/svg/cards/default.svg";

export interface CardInterface {
    onClick: Function;
    selected?: boolean;
    flipping?: boolean;
    matched?: boolean;
    value: string;
}

const Card: FC<CardInterface> = ({onClick, selected, value, flipping, matched }) => {
    const canClick = !selected && !matched && !flipping;

    const click = () => { if (canClick) onClick(); }

    return (
        <div
            className={classNames({
                card: true,
                selected,
                flipping,
                matched
            })}
            onClick={click}
        >
            <div className="back">
                <DefaultCard />
            </div>

            <div className="front">
                <img
                    src={`/image/svg/cards/${!canClick ? value as CardTypes : 'default'}.svg`}
                    alt={value}
                />
            </div>
        </div>
    )
}

export default Card;
