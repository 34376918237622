import React, {FC, useEffect, useState} from 'react'
import Ball from "../../static/image/svg/ball.svg";

interface TimerProperties {
    initialTime: number;
    remainingTime: number;
}

const Timer: FC<TimerProperties> = ({ initialTime, remainingTime}) => {
    const [percentage, setPercentage] = useState<number>(100);

    useEffect(() => {
        if (remainingTime <= 0) return;
        setPercentage(((100 / initialTime) * remainingTime)) // TODO: improve penalty
    }, [remainingTime])

    return (
        <div className="timer">
            <div
                className="animation"
                style={{
                    width: `${percentage}%`
                }}
            >
                <Ball />
            </div>
        </div>
    )
}

export default Timer;
