import * as React from "react";
import {useContext} from "react";
import Logo from "../../static/image/svg/logo.svg";
import classNames from "classnames";
import {GameContext, GameContextPage} from "../game/GameContext";

const Layout: React.FC = ({ children }) => {
    const { pageId, setPageId } = useContext(GameContext);

    return (
        <main className={classNames(pageId === GameContextPage.Instructions ? 'gradient' : null)}>
            <div className="header">
                <a onClick={() => setPageId(GameContextPage.Landing)}>
                    <Logo/>
                </a>
            </div>
            {children}
        </main>
    )
};

export default Layout;
