import React, {FC, useEffect, useState} from 'react'
import Combo from "../../static/image/svg/combo.svg";
import DoubleCombo from "../../static/image/svg/double-combo.svg";
import Bounce from 'react-reveal/Bounce';
import {ComboType} from "../util/combo";

interface ScoreProperties {
    score: number;
}

const Score: FC<ScoreProperties> = ({ score}) => {
    const [internalScore, setInternalScore] = useState<number>(0);
    const [combo, setCombo] = useState<ComboType | boolean>(false);
    const [comboTimeout, setComboTimeout] = useState<NodeJS.Timeout>();

    useEffect(() => setInternalScore(score), [score]);

    useEffect(() => {
        if(combo === false) return;
        if (comboTimeout) clearTimeout(comboTimeout)
        setComboTimeout(setTimeout(() => setCombo(false), 2500))
    }, [combo])

    useEffect(() => {
        if ((score - internalScore) === 10) setCombo(ComboType.SINGLE);
        if ((score - internalScore) === 15) setCombo(ComboType.DOUBLE);
    }, [score])

    return (
        <div className="score">
            <div className="current">
                <h4>Jouw score</h4>
                <h1>{('000' + score).substr(-3)}</h1>
            </div>

            {combo === ComboType.SINGLE &&
                <Bounce duration={500}>
                    <Combo />
                </Bounce>
            }

            {combo === ComboType.DOUBLE &&
                <Bounce duration={500}>
                    <DoubleCombo />
                </Bounce>
            }
        </div>
    )
}

export default Score;
