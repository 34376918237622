import React, {useEffect, useState} from 'react';
import Card from "../components/card";
import {generateDeck} from "../util/cards";

const useGame = (time: number) => {
    const [matches, setMatch] = useState<any>([]);
    const [flipping, setFlip] = useState<any>({i:[], v:[]});
    const [deck, setDeck] = useState(generateDeck());
    const [timeRemaining, setTimeRemaining] = useState<number>(time);
    const [streak, setStreak] = useState<number>(0);
    const [score, setScore] = useState<number>(0);

    const updateScore = (score: number) => {
        if (score <= 0) return;
        setScore(score);
    }

    // Check if current view is matched
    const completed = matches.length === deck.length / 2;

    // Automatic reshuffle
    useEffect(() => {
        if (!completed) return;

        // Reset streak
        setStreak(0);

        // Reshuffle cards
        setTimeout(() => {
            setFlip({i:[], v:[]});
            setMatch([]);
            setDeck(generateDeck())
        }, 500)
    }, [completed])

    useEffect(() => {
        if (flipping.i.length !== 2) return;

        if (flipping.v[0] === flipping.v[1]) {
            setMatch([...matches, flipping.v[0]]);
            setFlip({i:[], v:[]});

            // Calculate streak
            const currentStreak = streak + 1

            // Increase score, according to streak
            updateScore(score + (currentStreak * 5));

            // Set streak
            setStreak(currentStreak);
        } else {
            // Decrease score by 1
            updateScore(score - 1);

            // Reset streak
            setStreak(0);

            // Flip cards back around
            setTimeout(() => {
                setFlip({i:[], v:[]});
            }, 500);
        }
    }, [flipping]);

    useEffect(() => {
        if (timeRemaining === 0) return;
        setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000)
    }, [timeRemaining])

    const onClick = (value: any, index: any) => {
        const {i, v} = flipping;
        if (i.length < 2) {
            setFlip({i:[...i, index],v:[...v, value]});
        }
    }

    const cards = deck.map((value, i) => (
        <Card key={i}
              onClick={() => onClick(value, i)}
              value={value}
              matched={matches.includes(value)}
              flipping={flipping.i.includes(i)}
        />
    ));

    return [cards, timeRemaining, score];
}

export default useGame;
