import * as React from "react";
import {useContext, useEffect, useState} from "react";
import useGame from "../hooks/useGame";
import Timer from "../components/timer";
import Score from "../components/score";
import {AxiosResponse} from 'axios';
import { AES } from "crypto-js";
import {httpClient} from "../util/axios";
import {GameContext, GameContextPage} from "../game/GameContext";

const GamePage: React.FC = () => {
    const { setPageId } = useContext(GameContext);
    const dummyTime = 30;
    const [cards, timeRemaining, score] = useGame(30);
    const [dummyTimeRemaining, setDummyTimeRemaining] = useState<number>(dummyTime);
    const [dummyScore, setDummyScore] = useState<number>(0);
    const [token, setToken] = useState<string | undefined>();
    const intervals = [
        30,
        50,
        23,
        51,
        36,
        74
    ]

    const getSecret = (token: string) => {
        const offset = intervals[Math.floor(Math.random() * intervals.length)];
        return `f9234${token.substr(offset, 25).split("").reverse().join("")}238b`
    }

    useEffect(() => {
        if (!token) return;
        window?.sessionStorage?.setItem('token', token)
    }, [token])

    useEffect(() => {
        // Create new game at the server
        httpClient.post('/').then((result: AxiosResponse<{ token: string }>) => {
            setToken(result.data.token);
        }).catch(() => {
            setPageId(GameContextPage.Error)
        })
    }, [])

    useEffect(() => {
        // Destroy on tick mismatch
        if (
            timeRemaining !== dummyTime &&
            (dummyTimeRemaining - (timeRemaining as number)) !== 1
        ) { setPageId(GameContextPage.Landing) }

        setDummyTimeRemaining(timeRemaining as number)

        if (timeRemaining === 0 && token) {
            // Push obfuscated result request
            httpClient.put('/', {
                score: token,
                token: AES.encrypt(`${score}`, getSecret(token)).toString()
            }).then(() => {
                setPageId(GameContextPage.Submit)
            }).catch(() => {
                setPageId(GameContextPage.Error)
            })
        }
    }, [timeRemaining])

    useEffect(() => { setDummyScore(score as number) }, [score]);

    return (
        <div className="game">
            <Timer initialTime={dummyTime} remainingTime={dummyTimeRemaining} />

            <Score score={dummyScore} />

            <div className="cards">
                {cards}
            </div>
        </div>
    )
}

export default GamePage;
