export enum CardTypes {
    AUSTRIA = 'at',
    BELGIUM = 'be',
    SWITZERLAND = 'ch',
    CZECH_REPUBLIC = 'cz',
    GERMANY = 'de',
    DENMARK = 'dk',
    SPAIN = 'es',
    FINLAND = 'fi',
    FRANCE = 'fr',
    UNITED_KINGDOM = 'gb',
    CROATIA = 'hr',
    HUNGARY = 'hu',
    ITALY = 'it',
    MACEDONIA = 'mk',
    NETHERLANDS = 'nl',
    POLAND = 'pl',
    PORTUGAL = 'pt',
    RUSSIA = 'ru',
    SCOTLAND = 'sc',
    SWEDEN = 'se',
    SLOVAKIA = 'sk',
    TURKEY = 'tr',
    UKRAINE = 'ua',
    WALES = 'wls'
}

export function generateDeck() {
    const deck = Object.values(CardTypes)
        .sort(() => 0.5 - Math.random())
        .slice(0, 3);
    return deck.concat([...deck]).sort(() => 0.5 - Math.random())
}
