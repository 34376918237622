import * as React from "react";
import {GameContext, GameContextPage} from "../game/GameContext";
import {useContext} from "react";

const ErrorPage: React.FC = () => {
    const { setPageId } = useContext(GameContext);

    return (
        <div className="error">
            <div className="text">
                <h1>
                    Oeps, er ging iets fout!
                </h1>
                <p>
                    We konden het spel niet starten. Controleer uw internet verbinding en probeer het nogmaals.
                </p>
            </div>

            <a onClick={() => setPageId(GameContextPage.Landing)}>
                Terug naar het spel
            </a>
        </div>
    )
}

export default ErrorPage;
