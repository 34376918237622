import React, {createContext} from "react";

export enum GameContextPage {
    Landing, Instructions, Game, Submit, Conditions, Error
}

interface GameContextProps {
    pageId: GameContextPage;
    setPageId: (value: GameContextPage) => void;
}

export const GameContext = createContext<GameContextProps>({
   pageId: GameContextPage.Landing, setPageId: () => { },
});
