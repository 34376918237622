import React from "react";
import Layout from "../components/layout";
import InfoPage from "./info";
import {GameContextProvider} from "../game/GameContextProvider";
import {GameContext, GameContextPage} from "../game/GameContext";
import InstructionsPage from "./instructions";
import GamePage from "./game";
import SubmitPage from "./submit";
import ConditionsPage from "./conditions";
import ErrorPage from "./error";

// Load styles
import "../styles/index.scss";
import "../styles/fonts.scss"

const App: React.FC = () => {
    const isBrowser = typeof window !== "undefined";

    const renderPage = (pageId: GameContextPage) => {
        switch(pageId) {
            default:
            case GameContextPage.Landing:
                return <InfoPage />;
            case GameContextPage.Instructions:
                return <InstructionsPage />;
            case GameContextPage.Game:
                return <GamePage />;
            case GameContextPage.Submit:
                return <SubmitPage />;
            case GameContextPage.Conditions:
                return <ConditionsPage />;
            case GameContextPage.Error:
                return <ErrorPage />;
        }
    };

    return (
        <GameContextProvider>
          <Layout>
              <div className="container">
                  <GameContext.Consumer>
                        {value =>  isBrowser ? renderPage(value.pageId) : <></>}
                  </GameContext.Consumer>
              </div>
          </Layout>
        </GameContextProvider>
  );
};
export default App;
