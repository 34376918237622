import * as React from "react";
import Cup from "../../static/image/svg/cup.svg";
import FacebookIcon from "../../static/image/svg/facebook.svg";
import {FacebookShareButton} from "react-share";
import PlayAndWin from "../../static/image/svg/play-and-win.svg";
import Replay from "../../static/image/svg/replay.svg";
import Participate from "../../static/image/svg/participate.svg";
import Branding from "../../static/image/svg/branding.svg";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from "classnames";
import {useContext, useEffect, useState} from "react";
import {httpClient} from "../util/axios";
import {AxiosResponse} from "axios";
import {GameContext, GameContextPage} from "../game/GameContext";

interface FormInterface {
    name?: string;
    locality?: string;
    email?: string;
    termsOfService?: number;
}

interface HighScoreInterface {
    name: string;
    score: number;
}

const SubmitPage: React.FC = () => {
    const { setPageId } = useContext(GameContext);
    const [token, setToken] = useState<string>();
    const [score, setScore] = useState<number>();
    const [submitted, setSubmitted] = useState<boolean>();
    const [error, setError] = useState<boolean>();
    const [highScores, setHighScores] = useState<HighScoreInterface[]>();
    const quote = `Versla mijn score van ${score} punten! Scoor in 30 seconden zoveel mogelijk punten en maak kans op een Samsung UHD 55” TV t.w.v. €599!`;
    const hashtag = '#playenwin'
    const url = 'https://playscore.nl';

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, 'Naam is te kort')
            .max(50, 'Naam is te lang')
            .required('Naam is verplicht'),
        locality: Yup.string()
            .min(3, 'Plaatsnaam is te kort')
            .max(50, 'Plaatsnaam is te lang')
            .required('Plaatsnaam is verplicht'),
        email: Yup.string()
            .email('E-mailadres is ongeldig')
            .required('E-mailadres is verplicht'),
        termsOfService: Yup.boolean()
            .required('U dient akkoord te gaan met de voorwaarden')
            .oneOf([true], 'U dient akkoord te gaan met de voorwaarden')
    });

    const form = useFormik<FormInterface>({
        initialValues: {
            name: '',
            locality: '',
            email: '',
        },
        validateOnChange: false, // TODO: fix on change after submit?
        validateOnBlur: false,
        validationSchema,
        onSubmit: values => {
            httpClient.post('/contact', {
                token,
                ...values
            }).then(() => {
                window?.sessionStorage?.clear()
                setSubmitted(true);
                fetchHighScore()
            }).catch(() => {
                setError(true);
            })
        },
    });

    const fetchHighScore = () => {
        httpClient.get(`/high-scores`).then((result: AxiosResponse<HighScoreInterface[]>) => {
            setHighScores(result.data);
        })
    }

    useEffect(() => {
        const token = window?.sessionStorage?.getItem('token')
        if (!token) {
            setPageId(GameContextPage.Landing)
            return;
        }
        setToken(token);

        httpClient.get(`/?token=${token}`).then((result: AxiosResponse<{ score: number }>) => {
            setScore(result.data.score);
        })

        fetchHighScore()
    }, [])

    return (
        <div className="submit">
            <PlayAndWin className="logo" />

            {!isNaN(score as number) ?
                <div className="score">
                    <Cup />

                    <div className="current">
                        <h2>Jouw score</h2>
                        <h1>{('000' + score).substr(-3)}</h1>
                    </div>
                </div> :
            null}

            <div className="share">
                <p>Deel mijn score op</p>

                <FacebookShareButton
                    url={url}
                    quote={quote}
                    hashtag={hashtag}
                >
                    <FacebookIcon />
                </FacebookShareButton>
            </div>

            {highScores ?
                <div className="highscores">
                    <h1>High scores</h1>
                    {highScores.map((highScore, index) => (
                        <div key={index} className="highscore">
                            <p className="position">
                                {index + 1}
                            </p>
                            <p className="name">
                                {highScore.name}
                            </p>
                            <p className="score">
                                {('000' + highScore.score).substr(-3)}
                            </p>
                        </div>
                    ))}
                </div>
            : null}

            <a className="replay" onClick={() => setPageId(GameContextPage.Instructions)}>
                <Replay />
            </a>

            {submitted ?
                <div className="instructions">
                    <h1>Deelname opgeslagen</h1>
                    <p>Gefeliciteerd! Uw deelname is opgeslagen. De winnaars worden op 11 juli 2021 bekend gemaakt.</p>
                </div> :
                <div className="instructions">
                    <h1>Laat uw gegevens achter</h1>
                    <p>en maak kans op een Samsung UHD 55” TV t.w.v. &euro;599 of win één van de 3 JBL Go3 troostprijzen voor de laagste scores.</p>
                </div>
            }

            {error ?
                <div className="instructions">
                    <h1>Oeps, er ging iets fout</h1>
                    <p>Er ging iets fout bij het opslaan van uw deelname. Controleer uw verbinding en probeer het opnieuw.</p>
                </div> :
                null
            }


            {!submitted ?
                <form className="form" onSubmit={form.handleSubmit}>
                    <input
                        type="text"
                        placeholder="Naam"
                        name="name"
                        onChange={form.handleChange}
                        value={form.values.name}
                        className={classNames(form.errors.name ? 'error' : null)}
                    />
                    {form.errors.name ? <span>{form.errors.name}</span> : null}

                    <input
                        type="text"
                        placeholder="Plaats"
                        name="locality"
                        onChange={form.handleChange}
                        value={form.values.locality}
                        className={classNames(form.errors.locality ? 'error' : null)}
                    />
                    {form.errors.locality ? <span>{form.errors.locality}</span> : null}

                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={form.handleChange}
                        value={form.values.email}
                        className={classNames(form.errors.email ? 'error' : null)}
                    />
                    {form.errors.email ? <span>{form.errors.email}</span> : null}

                    <div className="consent">
                        <div className={classNames('consent-container', form.errors.termsOfService ? 'error' : null)}>
                            <input
                                type="checkbox"
                                onChange={form.handleChange}
                                name="termsOfService"
                                value={form.values.termsOfService}
                            />

                            <a onClick={() => setPageId(GameContextPage.Conditions)}>
                                Ik ga akkoord met de actievoorwaarden
                            </a>

                        </div>

                        {form.errors.termsOfService ? <span>{form.errors.termsOfService}</span> : null}
                    </div>

                    <button type="submit" className="submit">
                        <Participate />
                    </button>
                </form>
            : null }

            <p className="offer">
                Klik <a href="/files/aanbiedingen.pdf" target="_blank" rel="noopener noreferrer">hier</a> en profiteer nu van alle Samsung EK aanbiedingen!
            </p>

            <a
                href="https://comceptum.nl"
                className="branding"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Branding />
            </a>
        </div>
    )
}

export default SubmitPage;
